@font-face {
    font-family: 'Cisco Sans';
    src: url('CiscoSans.eot');
    src: local('Cisco Sans'), local('CiscoSans'),
        url('CiscoSans.eot?#iefix') format('embedded-opentype'),
        url('CiscoSans.woff2') format('woff2'),
        url('CiscoSans.woff') format('woff'),
        url('CiscoSans.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Cisco Sans';
    src: url('CiscoSans-Oblique.eot');
    src: local('Cisco Sans Oblique'), local('CiscoSans-Oblique'),
        url('CiscoSans-Oblique.eot?#iefix') format('embedded-opentype'),
        url('CiscoSans-Oblique.woff2') format('woff2'),
        url('CiscoSans-Oblique.woff') format('woff'),
        url('CiscoSans-Oblique.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Cisco Sans';
    src: url('CiscoSans-Heavy.eot');
    src: local('Cisco Sans Heavy'), local('CiscoSans-Heavy'),
        url('CiscoSans-Heavy.eot?#iefix') format('embedded-opentype'),
        url('CiscoSans-Heavy.woff2') format('woff2'),
        url('CiscoSans-Heavy.woff') format('woff'),
        url('CiscoSans-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Cisco Sans';
    src: url('CiscoSans-ExtraLightOblique.eot');
    src: local('Cisco Sans ExtraLight Oblique'), local('CiscoSans-ExtraLightOblique'),
        url('CiscoSans-ExtraLightOblique.eot?#iefix') format('embedded-opentype'),
        url('CiscoSans-ExtraLightOblique.woff2') format('woff2'),
        url('CiscoSans-ExtraLightOblique.woff') format('woff'),
        url('CiscoSans-ExtraLightOblique.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Cisco Sans';
    src: url('CiscoSans-HeavyOblique.eot');
    src: local('Cisco Sans Heavy Oblique'), local('CiscoSans-HeavyOblique'),
        url('CiscoSans-HeavyOblique.eot?#iefix') format('embedded-opentype'),
        url('CiscoSans-HeavyOblique.woff2') format('woff2'),
        url('CiscoSans-HeavyOblique.woff') format('woff'),
        url('CiscoSans-HeavyOblique.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Cisco Sans';
    src: url('CiscoSans-ThinOblique.eot');
    src: local('Cisco Sans Thin Oblique'), local('CiscoSans-ThinOblique'),
        url('CiscoSans-ThinOblique.eot?#iefix') format('embedded-opentype'),
        url('CiscoSans-ThinOblique.woff2') format('woff2'),
        url('CiscoSans-ThinOblique.woff') format('woff'),
        url('CiscoSans-ThinOblique.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Cisco Sans';
    src: url('CiscoSans-Bold.eot');
    src: local('Cisco Sans Bold'), local('CiscoSans-Bold'),
        url('CiscoSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('CiscoSans-Bold.woff2') format('woff2'),
        url('CiscoSans-Bold.woff') format('woff'),
        url('CiscoSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Cisco Sans';
    src: url('CiscoSans-BoldOblique.eot');
    src: local('Cisco Sans Bold Oblique'), local('CiscoSans-BoldOblique'),
        url('CiscoSans-BoldOblique.eot?#iefix') format('embedded-opentype'),
        url('CiscoSans-BoldOblique.woff2') format('woff2'),
        url('CiscoSans-BoldOblique.woff') format('woff'),
        url('CiscoSans-BoldOblique.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Cisco Sans';
    src: url('CiscoSans-ExtraLight.eot');
    src: local('Cisco Sans ExtraLight'), local('CiscoSans-ExtraLight'),
        url('CiscoSans-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('CiscoSans-ExtraLight.woff2') format('woff2'),
        url('CiscoSans-ExtraLight.woff') format('woff'),
        url('CiscoSans-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Cisco Sans';
    src: url('CiscoSans-Thin.eot');
    src: local('Cisco Sans Thin'), local('CiscoSans-Thin'),
        url('CiscoSans-Thin.eot?#iefix') format('embedded-opentype'),
        url('CiscoSans-Thin.woff2') format('woff2'),
        url('CiscoSans-Thin.woff') format('woff'),
        url('CiscoSans-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

