@import url("../src/assets/font/stylesheet.css");
* {
}
.violet{
  color: #875AE0;
}
.blue{
  color: #283AC7;
}
*:focus{
  outline: none;
}
body {
  font-family: "Cisco Sans";
  color: #fff;
}
a:hover,
a:focus {
  text-decoration: none;
}
h1{
  font-size: 52px;
}
h2{
  font-size: 48px;
}
h3{
  font-size: 36px ;
}
h4{
  font-size: 22px;
}
h5{
  font-size: 16px;
}
a:hover {
  color: #73c5eb;
  text-decoration: none;
}
p {
  font-size: 16px;
  color: #121212;
  font-weight: 400;
}
.float-right{
  float: right !important;
}
.float-left{
  float: left !important;
}
.font-16{
  font-size: 16px !important;
}
.white{
  color: #fff !important;
}
.cards {
  padding: 0 0 50px 0;
}
.slider-main {
  background: #f7f7f7;
}
.logo{
  width: 50%;
}
#hero .container h1 {
  margin: 0;
}
#hero .container h3 {
  font-size: 22px;
  margin: 0;
  padding: 30px 0;
}
.btn-get-started:hover {
  background: #122194;
}
#hero .container p {
  font-weight: 300;
  padding:0 0 30px;
  }
.header-link a{
  font-size: 15px;
    color: #000;
    border: 1px solid transparent;
    padding: 10px 25px;
    margin: 0 15px 0 0;
    border-radius: 20px;
    transition: 0.5s;
    display: inline-block;
}
.header-link a.login{
  background-color:#fff ;

}
.header-link a.sign-in{
  background-color: #283AC7;
  color: #fff;
}
.header-link {
  display: block;
  margin: 20px 0 0 0;
}

.hero-img img{
  width: 100%;
  height: auto;
}
.numbers-bull span.green,
.slider-cards .card.green {
  background: #30c9b0;
}
.numbers-bull span.orange,
.slider-cards .card.orange {
  background: #ff9d52;
}
.slider-cards .card.purple {
  background: #e2cafc;
}
.bann .carousel-initialized{
  padding-bottom: 0px  !important;
}
#header {
  transition: all 0.5s;
  z-index: 997;
  padding: 15px 0;
  color: #000;
  background-color: #F7F7F7;
}
header h1 a img {
  width: 100%;
}
header h1 a {
  display: inline-block;
  color: #000;
  width: 112px;
  height: 53px;
}
#hero {
  width: 100%;
  height: 510px;
  background: #F7F7F7;
  color: #000;
}
#hero p{
  color: #121212;
}
section#hero {
  padding: 0 0px 0 ;
}
section {
  padding: 0px;
  overflow: hidden;
  color: #fff;
}
.fixed-top {
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
#hero .hero-desc{
  padding-top: 30px;
}
#hero .container {
  padding-top: 0px;
}
.btn-get-started:focus, .btn-get-started:hover{
  color: #fff !important;
}
.btn-get-started {
  background: #283AC7;
  border-radius: 26px;
  color: #fff;
  padding: 14px 31px;
  margin-top: 20px;
  display: inline-block;
  margin: 0 15px 0 0;
  transition: 0.5s;
  line-height: 24px;
}
.numbers-bull span {
  display: -webkit-inline-box;
  background: #3B3B3B;
  padding: 20px 27px;
  border-radius: 50%;
}
.grid-details h3 {
  font-size: 36px;
  color: #121212;
  line-height: 56px;
  padding-bottom: 15px;
}
.grid-details p {
  font-size: 16px;
  color: #000;
  line-height: 26px;
}
.grid-details a {
  color: #171b1f;
  font-size: 18px;
  display: block;
}
.line {
  border-right: 1px solid #cccccc;
}
.sign-up h3 {
  color: #171b1f;
  font-size: 36px;
  line-height: 46px;
}
.slider-cards .card {
  width: 95% !important;
  height: 410px;
  float: left;
  margin-right: 1%;
  margin-bottom: 45px;
  padding: 36px;
}
.slider-cards{
  overflow: hidden;
  margin-top: 40px;
}
.link-list{
  padding:50px 0 30px;
}
.card h3 {
  font-size: 24px;
  padding: 60px 0 20px 0;
}
#hero button.carousel-arrow {
  position: absolute;
    bottom: 0 !important;
    top: 355px !important;
    left: 0 !important;
    display: block !important;
    border-radius: 22px;
    border: 1px solid rgb(0 0 0 / 0.2);
    height: 40px;
    padding: 10px;
}
#hero button.carousel-arrow.block.carousel-next {
  left: 45px !important;
}
.quick-connect .links ul li a {
  font-size: 16px;
  color: #005E7D;
}
.quick-connect .links ul li {
  padding: 5px 0;
}
.quick-connect .links ul {
  padding: 0;
  margin: 0 0 0 0;
}
.quick-connect .links {
  margin: 20px 0 0 0;
}
.quick-connect {
  padding: 0 0 0 20px;
}
.features{
  background-color: #fff;
  padding: 30px 0 100px;
}
.features h3{
  color: #000;
  padding: 0 0 30px 0;
}
.features .card{
  height: 410px;
  background-color: #283AC7;
  cursor: pointer;
  width: 410px;
}
.features .card .image{
  padding: 105px 35% 70px ;
}
.features .card .desc{
  padding: 0 10%;
}
.features .card .desc p{
  color: #fff;
  line-height: 26px;
  padding-top: 9px;
}
.why-webex{
  background-color: #F7F7F7;
  padding:80px 0;
}
.why-webex h2{
  color: #121212;
  margin: 0;
}
.why-webex span{
  display: block;
  color: #875AE0;
}
.why-webex ul{
  padding: 0;
  color: #000;
  list-style: none;
  padding-left: 20px;
}
.why-webex ul li{
  color: #000;
  padding: 7px 0;
  font-size: 16px;
  font-weight: 500;
}
.why-webex ul li::before {
  content: "\2022";
  color: #875AE0;
  font-weight: bold;
  display: inline-block; 
  width: 1em;
  margin-left: -1em;
}
.why-webex p{
  font-size: 22px;
  padding: 10px 0 0;
  font-weight: 500;
  line-height: 32px;
}
.sign-up{
  background-color: #283AC7;
  padding: 60px 0 80px;
}
.link-list .cards h2{
  color: #000;
  font-size: 36px;
}
.link-list-header{
  padding: 0px 0 40px;
  overflow: hidden;
}
.numbers-bull.register {
  background: url(../src/img/register.png) no-repeat;
  background-position: 74px 10px; 
}
.numbers-bull.experience {
  background: url(../src/img/experience.png) no-repeat;
  background-position: 74px 10px; 
}
.numbers-bull.feedback {
  background: url(../src/img/feedback.png) no-repeat;
  background-position: 74px 10px; 
}
.footer .footer-width span{
  background: url(../src/img/right-arrow.svg) no-repeat;
  background-size: 8%;
  display: block;
  padding: 0px 0 0 45px;
  font-size: 17px;
  color: #000;
  background-position: left;

}
.numbers-bull {
  background-size: 12% !important;
}
.footer .card h4 {
  color: #121212;
  font-weight: 500;
  font-size:22px;
  margin:0;
  padding: 0px 0 40px;
  line-height: 32px;
  font-weight: 500;
}
.footer{
  background-color: #F7F7F7;
  padding: 105px 0 50px 0;
}
.footer .footer-width{
  max-width:900px ;
  margin: 0 auto 80px;
}
.footer .card{
  padding: 30px;
  width: 410px;
  display: inline-block;
  background-color: #fff;
  text-align: left;
  margin-right: 30px;
  transition: 0.3s ;
  cursor: pointer;
}
.footer .card:hover{
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16), 0px 0px 1px rgba(0, 0, 0, 0.14);
}
.footer .card:last-child{
  margin: 0;
}
footer {
  text-align: center;
}
.sign-up .button:hover {
  background: #EDEDED;
}
.sign-up .button{
  border:0;
  line-height: 24px;
  padding: 12px 30px;
  border-radius: 25px;
  color: #000;
  font-size: 15px;
  margin: 30px 0 0 0;
  background: #fff;
  transition: 0.5s;
  display: inline-block;
}
.row.footer-width .card p {
  font-size: 22px;
}
.hover-card {
  opacity: 0;
  position: absolute;
  top: 0;
  padding: 40px;
  color: #121212;
  transition: 0.3s; 
}
.features .card:hover .hover-card {
  opacity: 1;
}
.features .card:hover .card-wrapper {
  opacity: 0;
}
.features .card:hover {
  background: #F7F7F7;
}
.header-link a.login:hover {
  background-color: #EDEDED;
}
.header-link a.sign-in:hover {
  background-color: #122194;
}
footer img.footer-logo {
  margin-bottom: 20px;
  display: initial;
   width: 60px;
  height: 32px;
}
.features h4 {
  display: table-cell;
  height: 65px;
  vertical-align: bottom;
  line-height: 34px;
}
.features .hover-card h4{
  vertical-align: bottom;
  line-height: 34px;
  height: auto;
}
.features .card .image img {
  width: 110px;
  height: 110px;
  object-fit: contain;
}
p.why-p{
  line-height: 26px;
  padding-bottom: 8px;
    margin: 0;
}
.hero-img.sec-banner img {
  width: 90%;
}
.hero-img {
  text-align: right;
}
.hero-img.sec-banner{
  padding-right: 0;
}
.features .desc h4{
  text-align: center;
  display: block;
}