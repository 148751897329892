@media only screen and (min-width: 1290px) {
    .container{
        width: 100%;
        max-width: 1290px;
    }
}
@media only screen and (max-width: 1290px) {
    .container{
        width: 100%;
        max-width: 1290px;
    }
    .features .card{
        width: auto;
    }
}
@media only screen and (max-width: 1024px) {
    .hero-img{
        display: none;
    }
    .features .card{
        margin-bottom: 30px;
        height: auto;
        padding-bottom: 20px;
    }
    .link-list-header {
        padding: 0px 40px 40px;
    }
    .link-list .grid-layout {
        max-width: 400px;
        margin:  0 auto;
    }
    .why-webex {
        padding: 50px 0 40px;
    }
    .why-webex img{
        display: none;
    }
    .why-webex .desc{
        margin-top: 10px;
    }
    .footer {
        padding: 60px 0 50px 0;
    }
    .hero-img {
        width: 400px;
        margin:25px auto;
    }
    #hero button.carousel-arrow{
        top:620px !important;
    }
}
@media only screen and (max-width: 768px) {
    .features .card .image{
        padding: 20px 35% 90px;
    }
    .hero-img{
        display: none;
    }
    .hero-img {
        width: 720px;
        margin:25px auto;
    }
    #hero button.carousel-arrow{
        top:840px !important;
    }
    img{
        width:100%;
    }
    .footer .card{
        margin-right: 0;
        margin-bottom: 30px;
    }
    .footer .footer-width{
        max-width: 450px;
        margin: 0 auto 40px;
    }
    .footer {
        padding: 40px 0 70px 0;
    }
}
@media only screen and (max-width: 600px) {
    #hero .container h3{
        font-size: 20px;
    }
    #hero{
        height: 595px;
    }
    .bann .carousel-initialized{
        padding-bottom: 8px !important;
    }
    .footer .card h4{
        font-size:20px;
    }
    h1{
        font-size: 41px;
    }
    h2{
        font-size: 38px;
    }
    .why-webex p{
        font-size: 20px;
    }
    .hero-img {
        display: none;
    }
    .logo {
        width: 30%;
    }
    .header-link a{
        padding: 10px 19px;
        display: inline-block;
    }
    .header-link{
        margin: 32px 0 0 0;
    }
    .link-list .grid-layout {
        padding: 0 30px;
    }
    .footer .card{
        width:320px
    }
    .sign-up h3{
        font-size: 28px;
        line-height: initial;
    }
    .sign-up{
        padding: 40px 0;
    }
    #hero button.carousel-arrow{
        top: 500px !important;
    }
    #hero span{
        display: block;
    }
    #hero .container {
        padding-top: 0px;
    }
}
@media only screen and (min-width: 320px) {
    
}
@media only screen and (orientation: landscape) and (max-width:850px) {
    #hero button.carousel-arrow{
        top: 280px !important;
    }
    #hero h1{
        font-size: 40px;
    }
    #hero .container h3 {
        font-size: 28px;
        margin: 0;
        padding: 10px 0;
    }
    .hero-img {
        display: none;
    }
    #hero .container {
        padding-top: 0;
    }
    .btn-get-started{
        margin-bottom: 30px;
    }
    .footer .card{
        width: 330px;
    }
    .sign-up{
        padding: 40px 0;
    }
}